html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  margin: 0 !important;
  padding: 0 !important;
}

* {
  box-sizing: border-box;
}

.h-fit {
  height: fit-content;
}

.w-full {
  width: 100%;
}

.flex-row > * {
  position: relative;
}

.ant-drawer-content-wrapper {
  width: 260px !important;
}

.ant-menu-light.ant-menu-root.ant-menu-inline {
  border: none;
}

.ant-drawer .ant-drawer-header {
  border: none;
}

.ant-input-outlined:focus-within,
.ant-input-outlined:hover {
  border-color: #00944f;
}

.ant-input-outlined:focus-within {
  box-shadow: 0 0 0 2px rgb(105 108 255 / 10%);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00944f;
  border-color: #00944f;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-checkbox-wrapper:not(
    .ant-checkbox-wrapper-disabled
  ):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: #78ffc0;
  border-color: transparent;
}

.ant-btn-primary {
  background: #00944f;
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background: #78ffc0;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):active,
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #00944f;
  border-color: #00944f;
  background: #ffffff;
}

.ant-input,
.ant-input-affix-wrapper {
  padding: 6px 11px;
  font-size: 15px;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background: initial;
  background-color: #00944f !important;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm:focus {
  box-shadow: 0 0 0 3px rgb(0 148 79 / 50%) !important;
}

.ant-drawer .ant-drawer-header {
  padding: 0px 24px 16px;
}

.ant-drawer .ant-drawer-body {
  padding: 0px !important;
}

.ant-menu-item {
  margin: 5px 0px !important;
  border-radius: 0px !important;
  width: 100% !important;
  font-size: 16px;
  letter-spacing: 1px;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-menu-light
  .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-menu-light
  > .ant-menu
  .ant-menu-item-selected {
  background-color: #e8ffe6;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-menu-light
  .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-menu-light
  > .ant-menu
  .ant-menu-item-selected {
  color: #00944f;
}

.logout-btn {
  padding: 8px 16px;
  color: rgb(160, 0, 0);
}

/* .ant-collapse-item .ant-collapse-header {
  align-items: center !important;
} */

.ant-collapse-content-box {
  background: #f5f5f5;
  padding: 10px 16px !important;
}

.icon-box .icon {
  font-size: 24px;
}

.icon-check {
  color: #00944f;
}

.icon-close {
  color: rgb(201, 0, 0);
}

.icon-eye {
  color: #555555db;
}
.ant-modal {
  top: calc(100% - 75%);
}
